import React from 'react'
import './catagory.scss'
import Navbar from '../../componet/navbar/Navbar'
import Footer from '../../pages/catagory/fotterA/Footerca'
import Lottie from "lottie-react";
import ani from "../../image/IvCqB0fY4G.json"
import ReactPlayer from 'react-player'
import tec from "../../image/s2qx0TdX6G.json";
import cod from "../../image/Tt56veHIQk.json";
import gra from "../../image/CPqkNgxiG3.json";

const Catagory = () => {
  return (
        <div className="cat">
            <Navbar/>
      <div className="sectionca">
      <Lottie className='lottie' animationData={ani} />
      <div className="p">
      <h3>
        Catagories
      </h3>
        </div>
    </div>
    <div className="catagory">
       <div className="tech">
       <Lottie className='lottie' animationData={tec} />
       <h3>Tech support</h3>
        <div className="techv">
        <div className="video-placeholder1"><ReactPlayer url={"https://www.youtube.com/watch?v=2JK8umLrjf0"} height={"500px"} width={"470"} loop controls/></div>
          <div className="video-placeholder1"><ReactPlayer url={"https://www.youtube.com/watch?v=GSBUJMAm3AU"} height={"500px"} width={"470"}loop controls/></div>
          <div className="video-placeholder1"><ReactPlayer url={"https://www.youtube.com/watch?v=P7x-BZUub1E"} height={"500px"} width={"470"}loop controls/></div>
          <div className="video-placeholder1"><ReactPlayer url={"https://www.youtube.com/watch?v=hS6e4bnOdXs"} height={"500px"} width={"470"}loop controls/></div>
        </div>
       </div>
       <div className="cod">
       <Lottie className='lottie' animationData={cod} />
       <h3>Coding</h3>
        <div className="codv">
        <div className="video-placeholder1"><ReactPlayer url={"https://www.youtube.com/shorts/7H5R3nVdaWU"} height={"500px"} width={"470"} loop controls/></div>
          <div className="video-placeholder1"><ReactPlayer url={"https://www.youtube.com/shorts/6XE-sg5RSCY"} height={"500px"} width={"470"}loop controls/></div>
          <div className="video-placeholder1"><ReactPlayer url={"https://www.youtube.com/shorts/3I4EGtIilKE"} height={"500px"} width={"470"}loop controls/></div>
          <div className="video-placeholder1"><ReactPlayer url={"https://www.youtube.com/watch?v=mq508HXzccI"} height={"500px"} width={"470"}loop controls/></div>
        </div>
       </div>
       <div className="gra">
       <Lottie className='lottie' animationData={gra} />
       <h3>Graphics desing</h3>
        <div className="grav">
        <div className="video-placeholder1"><ReactPlayer url={"https://www.youtube.com/watch?v=v1hw3VUnUYc"} height={"500px"} width={"470"} loop controls/></div>
          <div className="video-placeholder1"><ReactPlayer url={"https://www.youtube.com/watch?v=5tQ0hf2SCeo"} height={"500px"} width={"470"}loop controls/></div>
          <div className="video-placeholder1"><ReactPlayer url={"https://www.youtube.com/watch?v=9OJ4mT7P_NM"} height={"500px"} width={"470"}loop controls/></div>
          <div className="video-placeholder1"><ReactPlayer url={"https://www.youtube.com/watch?v=JFn2kK8GhUQ"} height={"500px"} width={"470"}loop controls/></div>
        </div>
       </div>
      </div>
            <Footer/>
        </div>
  )
}

export default Catagory