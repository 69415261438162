import React from 'react'
import './about.scss'
import Navbar from '../../componet/navbar/Navbar'
import Footer from '../../pages/about/fotterA/Footera'
import Lottie from "lottie-react";
// import ParticlesComponent from '../../component/config/particles';
import ani from "../../image/jl7J7xlb9Z.json"
// import doc from "../../image/IqSDnachVv.json"
import doc3 from "../../image/qMfsEBDmdZ.json"
import doc1 from "../../image/7DyYojvanJ.json"
import doc2 from "../../image/Wjtau4tyWt.json"



const About = () => {
  return (
    <div className="about">
      <Navbar/>
      <div className="section1">
      <Lottie className='lottie' animationData={ani} />
      <div className="p">
      <h3>
        About <span>us</span>
      </h3>
        </div>
    </div>
    <div className="tit">
      <h3>Our Company</h3>
    </div>
      <div className="detail">
        <h3>About us</h3>
        <p>
        To empower individuals with the necessary skills and knowledge to navigate the digital age effectively, from basic mobile usage to coding proficiency, while fostering awareness of mobile technology utilization, particularly in Ethiopia's countryside, and aiding telecom companies in resolving basic mobile-related queries.
        To create awareness among individuals in Ethiopia's countryside regarding the benefits and functionalities of mobile devices, promoting digital inclusion and empowerment.
        To equip participants with the ability to troubleshoot common mobile-related issues and utilize telecom services effectively, thereby reducing reliance on call centers for basic queries.

        </p>
      </div>
      
    <div className="detail2">
        <div className="de1">
        <Lottie className='lottie' animationData={doc3} />
        <p>
        At Mindpulse Technology, our vision is to be a leading provider of innovative technology solutions that drive growth, productivity, and success for our clients. We strive to be at the forefront of technological advancements, continuously pushing the boundaries of what is possible.
        </p>
        </div>
        <div className="de2">
        
        <p>
        Our mission is to leverage our expertise in technology to deliver value-driven solutions that exceed the expectations of our clients. We are passionate about helping businesses harness the power of technology to achieve their goals and stay ahead in today's competitive market.

        </p>

        <Lottie className='lottie' animationData={doc1} />
        </div>
        <div className="de3">
        <Lottie className='lottie' animationData={doc2} />
        <p>
         We embrace innovation and creativity in everything we do, constantly seeking new and better ways to solve problems and deliver value to our clients.        Commitment to delivering exceptional solutions and services that exceed customer expectations.
         conduct ourselves with the highest standards of integrity, honesty, and transparency, building trust and credibility with our clients, partners, and stakeholders        Upholding the highest standards of honesty, ethics, and transparency in all endeavors.
         We are committed to excellence in all aspects of our work, striving for quality, accuracy, and reliability in every project and endeavor.
  
        </p>

        
        </div>
   </div>
        <Footer/>
    </div>
    
  )
}

export default About