import "./login.scss";
import { useNavigate } from 'react-router-dom';



export default function Login() {
  const navigate  = useNavigate();

  const handleHome = () => {
    navigate("/Home");
  };

  const handleContentCreator = () => {
    window.open("http://uploader.tech.mindpulsetech.com/", "_blank");
  };

  return (
    <div className="login">
      <div className="top">
        <div className="wrapper">
          <img
            className="logo"
            src="../image/logo-removebg-preview.png"
            alt=""
          />
        </div>
      </div>
      <div className="container">
        <form>
          <h4>Sign In</h4>
          <input type="email" placeholder="Phone number" />
          <input type="password" placeholder="OTP" />
          <button onClick={handleHome} className="loginButton">Sign In</button>
          <span>
            New to Mind pulse? <b>Sign up now.</b>
          </span>
          <span className="s1">
            Sign as content creator? <b onClick={handleContentCreator}>Sign up now.</b>
          </span>
          <small>
            This page is protected by Google reCAPTCHA to ensure you're not a
            bot. <b>Learn more</b>.
          </small>
        </form>
      </div>
    </div>
  );
}
