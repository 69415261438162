import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from '../src/pages/home/Home'
import About from '../src/pages/about/About'
import Contact from '../src/pages/contact/Contact'
import Catagory from '../src/pages/catagory/Catagory'
import Login from '../src/pages/login/Login'
import DetailDoc from '../src/pages/detail/DetailDoc'
const App = () => {
  return (
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<Login />} />  {/* Route for Home */}
      <Route path="/Home" element={<Home />} />  {/* Route for Home */}
      <Route path="/Contact" element={<Contact />} />  {/* Route for Cause */}
      <Route path="/About" element={<About />} />  {/* Route for Cause */}
      <Route path="/Catagory" element={<Catagory />} />  {/* Route for Cause */}
      <Route path="/DetailDoc" element={<DetailDoc />} />  {/* Route for Cause */}
      {/* Add other routes here */}
    </Routes>
  </BrowserRouter>
  )
}

export default App