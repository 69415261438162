import React, { useEffect, useState } from 'react'
import './home.scss'
import { useNavigate } from 'react-router-dom';
import Navbar from '../../componet/navbar/Navbar'
import Footer from '../../componet/fotter/Footer';
import Lottie from "lottie-react";
import ani from "../../image/YpWfpPkBD6.json";
import fix from "../../image/zenViemZLs.json";
import tec from "../../image/s2qx0TdX6G.json";
import cod from "../../image/Tt56veHIQk.json";
import gra from "../../image/CPqkNgxiG3.json";
import ParticlesBackground from '../../componet/config/ParticlesBackground';
import ReactPlayer from 'react-player'
import { firestore } from '../../firebaseConfige'; 
import DetailDoc from '../../pages/detail/DetailDoc'
import 'firebase/compat/firestore';
const Home = () => {
  const [doctors, setDoctors] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const doctorsRef = firestore.collection('admin-user');
        const snapshot = await doctorsRef.get();
        const doctorsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDoctors(doctorsData);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    };

    fetchDoctors();
  }, []);
  const handleDetailDoc = (id) => {
    // Use navigate instead of navigator
    navigate("/DetailDoc");
  };
  const handleReadMore = () => {
    navigate("/About");
  };
  return (
     <div className="home">
          <Navbar/>
          <div className="section2">
        
        <Lottie className='lottie' animationData={ani} />
        <div className="p">
          <h3>
            Welcome to <span>Tech Wiz</span>
          </h3>
          <p>
          Mindpulse Technology is a dynamic and innovative technology company dedicated to revolutionizing the digital landscape. Founded in [2004], our company is committed to delivering cutting-edge solutions and services that empower individuals and businesses to thrive in the digital age          </p> 
        </div>
      </div>  
      <div className="mid-section">
      <ParticlesBackground />
        <Lottie className='lottie' animationData={fix} />
        <div className="p">
          <h3>
            About us
          </h3>
          <p>
          To empower individuals with the necessary skills and knowledge to navigate the digital age effectively, from basic mobile usage to coding proficiency, while fostering awareness of mobile technology utilization, particularly in Ethiopia's countryside, and aiding telecom companies in resolving basic mobile-related queries.          <br />
          collaborate with telecom companies to disseminate information and resources, enhancing customer education and support services.

          </p> 
          <button onClick={handleReadMore}>Read more</button>
        </div>
      </div>
      <div className="sec_mid">
      <div className="title">
          <h2>Our expertise</h2>
        </div>
        <div className="experts">
        <div onClick={""} className="mental">
            <Lottie className='lottie' animationData={tec} />
            <h2>Tech support</h2>
          </div>
          <div onClick={""} className="physical">
            <Lottie className='lottie' animationData={cod} />
            <h2>Coding course</h2>
          </div>
          <div onClick={""} className="spirit">
            <Lottie className='lottie' animationData={gra} />
            <h2>Graphics design</h2>
          </div>
        </div>
      </div>
      <div className="bon1">
         <a href=""><button onClick={""}>Read more</button> </a> 
        </div>
        <div className="docvids">
            <div className="v1">
            <div ><ReactPlayer url={"https://www.youtube.com/shorts/UUQl1JCeMsc"} height={"500px"} width={"300px"} loop controls/></div>
            </div>
            <div className="v2">
            <div ><ReactPlayer url={"https://www.youtube.com/shorts/MKT8oGmM3JI"} height={"500px"} width={"300px"}loop controls/></div>

            </div>
            <div className="v3">
            <div ><ReactPlayer url={"https://www.youtube.com/shorts/IFwbeC8p6qA"} height={"500px"} width={"300px"}loop controls/></div>

            </div> 
            <div className="v4">
            <div ><ReactPlayer url={"https://www.youtube.com/shorts/C8CaykUbp94"} height={"500px"} width={"300px"}loop controls/></div>

            </div> 
          </div>


        <div className="up">
        {doctors.map(doctor => (
        <div key={doctor.id} className="doctor" onClick={() => handleDetailDoc(doctor.id)}>
          {doctor.profileImage && <img src={doctor.profileImage} alt={doctor.name} width={"300px"} />} 
          <h2>{doctor.name}</h2> {/* Display profile image */}
        </div>
      ))}
        </div>
        <div className="btn2">
        <a href=""><button onClick={""}>Read more</button> </a> 
        </div>
        <Footer/>
     </div>
  )
}

export default Home