import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import "./navbar.scss";
import { AccountCircle, Notifications, Search, NotificationsNone, Language, Settings, Person } from "@material-ui/icons";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState("English"); 

  // Function to handle scrolling
  window.onscroll = () => {
    setIsScrolled(window.pageYOffset === 0 ? false : true);
    return () => (window.onscroll = null);
  };

  // Function to handle navigation
  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    // Implement logic for changing language (if needed)
  };


  return (
    <div className={isScrolled ? "navbar scrolled" : "navbar"}>
      <div className="container">
        <div className="topbarWrapper">
          <div className="topLeft">
            <img src="../image/logo-removebg-preview.png" alt="" />
          </div>
         
        </div>
        <div className="center">
          <div className="links">
            <span onClick={() => handleNavigation("/Home")}>Home</span>
            <span onClick={() => handleNavigation("/Catagory")}>Catagory</span>
            <span onClick={() => handleNavigation("/About")}>About us</span>
            <span onClick={() => handleNavigation("/Contact")}>Contact us</span>
          </div>
        </div>
        <div className="right">
        <div className="language-dropdown">
            <select
              value={selectedLanguage}
              onChange={(e) => handleLanguageChange(e.target.value)}
            >
              <option value="English">English</option>
              <option value="Spanish">Spanish</option>
              <option value="Afaan oromo">Afaan oromo</option>
              <option value="አማርኛ">አማርኛ</option>
              {/* Add more language options if needed */}
            </select>
          </div>
          <div className="search-icon">
            <Search />
          </div>

          <Person className="notification-icon" />
          
        </div>
      </div>
    </div>
  );
};

export default Navbar;
