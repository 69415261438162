import React from 'react'
import './footera.scss'
// import { useNavigate } from 'react-router-dom';

const Footera = () => {
    // const navigate  = useNavigate();


    // const handleAbout = () => {
    //     navigate("/About");
    //   };
    // const handleContact = () => {
    //   navigate("/Contact");
    // };
   

  return (
    <div class="footer1">
        <div class="container">
            <div class="row">
                <div class="footer-col">
                    <img src="./image/photo_2024-03-11_11-38-35-removebg-preview.png" alt="" />
                </div>
                <div class="footer-col">
                    <h4>Quick links</h4>
                    <ul>
                        {/* <li onClick={handleContact}>Contact us</li>
                        <li onClick={handleAbout}>About us</li>
                        <li onClick={handleContact}>Help</li>   */}
                        <span><li onClick={""}>Contact us</li></span>
                        <span><li onClick={""}>About us</li></span>
                        <span><li onClick={""}>Help</li></span>
                    </ul>
                </div>
                <div class="footer-col">
                    <h4>call us.</h4>
                    <ul>
                        <li>+251234567</li>
                        <li>+251246822</li>
                        <li>+251359765</li>
                        <li></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

  )
}

export default Footera;